import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { InternalLinkFragmentFragmentDoc } from 'graphql/link/InternalLink.generated';
import { DestinationFragmentDoc } from 'graphql/destination/Destination.generated';
export type HistoryProductRecordFragment = {
  __typename?: 'HistoryProductRecord';
  travelType?: Types.TravelType | null;
  productCase: string;
  link: {
    __typename?: 'InternalLink';
    caption?: string | null;
    uri: string;
    targetPageType: Types.PageType;
  };
  destinations?: Array<{
    __typename?: 'TravelDestination';
    id: string;
    name: string;
    type: Types.TravelDestinationType;
    mythosCode?: string | null;
    location?: {
      __typename?: 'GeoLocation';
      airport?: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      } | null;
      continent?: {
        __typename?: 'GeoObject';
        id: string;
        name: string;
        type: Types.GeoType;
      } | null;
      countryGroup?: {
        __typename?: 'GeoObject';
        id: string;
        name: string;
        type: Types.GeoType;
      } | null;
      country?: {
        __typename?: 'GeoObject';
        id: string;
        name: string;
        type: Types.GeoType;
      } | null;
      region?: {
        __typename?: 'GeoObject';
        id: string;
        name: string;
        type: Types.GeoType;
      } | null;
      destination?: {
        __typename?: 'GeoObject';
        id: string;
        name: string;
        type: Types.GeoType;
      } | null;
      resort?: {
        __typename?: 'GeoObject';
        id: string;
        name: string;
        type: Types.GeoType;
      } | null;
    } | null;
  }> | null;
};

export const HistoryProductRecordFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'historyProductRecord' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HistoryProductRecord' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'link' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'internalLinkFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'travelType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productCase' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destinations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'destination' },
                },
              ],
            },
          },
        ],
      },
    },
    ...InternalLinkFragmentFragmentDoc.definitions,
    ...DestinationFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
