import { DocumentNode } from '@apollo/client';
import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { useAuthentication } from '@hotelplan/components.common.auth';
import { AuthChannelType } from '@hotelplan/graphql.local-types';
import { QueryHookOptions, useQuery } from '@hotelplan/libs.context.req-ctx';
import { calculateNextPage } from '@hotelplan/libs.utils';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import type { ISearchControlState } from 'components/domain/searchControl/SearchControl.types';
import { useAddHistoryItem } from 'components/domain/searchControl/useAddHistoryItem';
import {
  GetRecentProductsHistoryDocument,
  GetRecentProductsHistoryQuery,
} from 'graphql/history/GetRecentProductsHistory.generated';
import {
  GetRecentSearchesHistoryDocument,
  GetRecentSearchesHistoryQuery,
  HistoryUnionResponseFragment,
} from 'graphql/history/GetRecentSearchesHistory.generated';
import { HistoryProductRecordFragment } from 'graphql/history/HistoryProductRecordFragment.generated';
import { PageFragmentFragment } from 'graphql/srl/SRLFragments.generated';
import SearchHistorySkeleton from './SearchHistory.skeleton';
import { HistoryType } from './SearchHistory.types';
import SearchHistoryGroup from './SearchHistoryGroup';
import SearchHistoryLoginLinks from './SearchHistoryLoginLinks';

export interface ISearchHistoryProps {
  onClick?(): void;
  className?: string;
}

const SearchHistoryWrapper = styled.div.attrs({
  'data-id': `search-history`,
})(
  sx2CssThemeFn({
    maxHeight: '700px',
    overflow: ['initial', 'auto'],
    bg: 'white',
    p: 4,
    boxShadow: [null, 'cardShadow'],
  })
);

const PER_PAGE_LIMIT = 5;

const defaultPage: PageFragmentFragment = {
  pageNumber: 0,
  resultsPerPage: PER_PAGE_LIMIT,
  resultsTotal: 0,
};

type AnyHistoryRecord =
  | HistoryUnionResponseFragment['recordUnions'][0]
  | HistoryProductRecordFragment;

function useHistory<Q = any>({
  doc,
  mapToItems,
  mapToPage,
}: {
  doc: DocumentNode;
  mapToItems(data: Q): AnyHistoryRecord[];
  mapToPage(data: Q): PageFragmentFragment;
}) {
  const { data, fetchMore, loading } = useQuery<
    Q,
    QueryHookOptions<any, { pageNumber: number }>['variables']
  >(doc, {
    variables: {
      pageNumber: 0,
    },
  });

  const items = data ? mapToItems(data) : [];
  const { pageNumber, resultsPerPage, resultsTotal } = data
    ? mapToPage(data)
    : defaultPage;

  const nextPage = calculateNextPage(pageNumber, resultsPerPage, resultsTotal);

  function onNextPage() {
    fetchMore({
      variables: {
        pageNumber: nextPage,
      },
    });
  }

  return {
    items,
    onNextPage,
    nextPage,
    loading,
  };
}

const SearchHistory: React.FC<ISearchHistoryProps> = props => {
  const { onClick, className } = props;
  const [t] = useTranslation('common');
  const { channelType } = useAuthentication();
  const b2cAnonymous = channelType === AuthChannelType.B2CAnonymous;

  const addHistoryItem = useAddHistoryItem();

  const historyOfProducts = useHistory<GetRecentProductsHistoryQuery>({
    doc: GetRecentProductsHistoryDocument,
    mapToPage({ history }) {
      return history.recentProducts.page;
    },
    mapToItems({ history }) {
      return history.recentProducts.productRecords;
    },
  });

  const historyOfSearches = useHistory<GetRecentSearchesHistoryQuery>({
    doc: GetRecentSearchesHistoryDocument,
    mapToPage({ history }) {
      return history.recentSearches.page;
    },
    mapToItems({ history }) {
      return history.recentSearches.recordUnions;
    },
  });

  const loading = historyOfProducts.loading && historyOfSearches.loading;

  const onHistoryItemClick = (searchState?: ISearchControlState) => {
    if (searchState) {
      addHistoryItem(searchState);
    }
    onClick && onClick();
  };

  return (
    <SearchHistoryWrapper className={`historyContainer ${className}`}>
      {loading && <SearchHistorySkeleton />}
      {!loading && (
        <>
          <SearchHistoryGroup
            items={historyOfProducts.items}
            historyType={HistoryType.PRODUCT}
            header={t('common:recentProducts.heading')}
            onHistoryItemClick={onHistoryItemClick}
            testId="history-products"
            onNextPage={
              historyOfProducts.nextPage
                ? historyOfProducts.onNextPage
                : undefined
            }
          />
          <SearchHistoryGroup
            items={historyOfSearches.items}
            historyType={HistoryType.SEARCH}
            header={t('common:recentSearch.heading')}
            onHistoryItemClick={onHistoryItemClick}
            testId="history-searches"
            onNextPage={
              historyOfSearches.nextPage
                ? historyOfSearches.onNextPage
                : undefined
            }
          />
          {b2cAnonymous && <SearchHistoryLoginLinks />}
        </>
      )}
    </SearchHistoryWrapper>
  );
};

export default SearchHistory;
