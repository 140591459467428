import { Trans, useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { Image } from '@hotelplan/components.common.image';
import {
  LoginLink,
  RegistrationLink,
} from '@hotelplan/components.common.my-account-forms';
import { PageType } from '@hotelplan/graphql.types';
import { LOGIN_REDIRECT_PAGE_PARAM_NAME } from '@hotelplan/libs.login-redirect';
import { getRouteByPageTypeLocale } from '@hotelplan/libs.router-config';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

const Wrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    marginBottom: [3, 4],
  })
);

const LoginLinksImage = styled(Image)(
  sx2CssThemeFn({
    flexShrink: 0,
    width: '50px',
    height: '38px',
    marginRight: 3,
    marginTop: 1,
  })
);

const LinksWrapper = styled.p(
  sx2CssThemeFn({
    variant: 'text.richText',
  })
);

const SearchHistoryLoginLinks: React.FC = () => {
  const {
    i18n: { language },
  } = useTranslation('common');
  const myAccountHistoryRoute = getRouteByPageTypeLocale(
    PageType.MyaccountMyhistory,
    language
  );
  const query = {
    [LOGIN_REDIRECT_PAGE_PARAM_NAME]: myAccountHistoryRoute.id,
  };

  return (
    <Wrapper>
      <LoginLinksImage
        resized={[]}
        src="/images/svg/streamline-icon-responsive-design-1.svg"
      />
      <LinksWrapper>
        <Trans
          i18nKey="common:recent.noAuthNotification"
          components={[
            <LoginLink key="login" language={language} query={query} />,
            <RegistrationLink
              key="register"
              language={language}
              query={query}
            />,
          ]}
        />
      </LinksWrapper>
    </Wrapper>
  );
};

export default SearchHistoryLoginLinks;
